import {
  DIFFICULTY,
  allCards,
  hardCoreCardsType_1,
  hardCoreCardsType_2,
} from '@/src/constants';
import { TCard } from './Game';
import { shuffle } from '@/src/utils';

export const generateCards = (difficulty: keyof typeof DIFFICULTY): TCard[] => {
  const cards: TCard[] = [];

  if (difficulty === DIFFICULTY.BASE) {
    const randomCardsForBase = shuffle<TCard>(allCards).slice(0, 6);
    const shuffleCards = shuffle<TCard>(
      randomCardsForBase.concat(randomCardsForBase),
    );

    cards.push(...shuffleCards);
  }

  if (difficulty === DIFFICULTY.HARD) {
    const randomCardsForHard = shuffle<TCard>(hardCoreCardsType_1).slice(0, 6);
    const pairCards = hardCoreCardsType_2.filter(({ type }) =>
      randomCardsForHard.find((r) => r.type === type),
    );

    const shuffleCards = shuffle<TCard>(randomCardsForHard.concat(pairCards));

    cards.push(...shuffleCards);
  }

  return cards.map((card, index) => ({
    ...card,
    id: index,
    isFound: false,
    isOpen: true,
    isChosen: false,
    isPointer: false,
  }));
};
