import styles from './Footer.module.scss';
import hhLogoSrc from '../../images/content/hh-logo.svg';
import sendEventToCounters from '@/src/counterEvents';

export const Footer = () => {
  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'footer', label });
  };

  return (
    <div className={styles.footer}>
      <a
        href="https://hh.ru/employer/15478?utm_source=vk_hh&utm_medium=referral&utm_campaign=special_project_vacancy"
        target="_blank"
        rel="noreferrer"
        onClick={() => sendAnalytics('vacancies')}
      >
        Вакансии
      </a>
      <div className={styles.footerCopyright}>
        <p>©&nbsp;VK 2024</p>
        <p className={styles.footerHH}>
          <img src={hhLogoSrc} alt="Логотип Хэдхантер" />
          <span>Бренд-центр</span>
        </p>
      </div>
    </div>
  );
};
