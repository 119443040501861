import { usePageContext } from '@/src/components/App/PageContext';
import styles from './Countdown.module.scss';
import classNames from 'classnames';
import { noop } from '@/src/utils/noop';
import { useEffect, useState } from 'react';

export const Countdown = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const [currentNumber, setCurrentNumber] = useState(3);

  let timerId: ReturnType<typeof setTimeout> | null;

  useEffect(() => {
    const timer = () => {
      if (currentNumber < 1) {
        timerId && clearInterval(timerId);

        changePage('game');

        return;
      }

      setCurrentNumber((prev) => prev - 1);
    };

    timerId = setInterval(timer, 1000);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [currentNumber]);

  return (
    <div className={classNames(styles.wrapper, styles[`wrapper--countdown`])}>
      <div className={styles.main}>
        <div className={styles.countdownList}>
          <span
            className={classNames(styles.countdownItem, {
              [styles[`countdownItem--active`]]: currentNumber <= 3,
            })}
          >
            1
          </span>
          <span
            className={classNames(styles.countdownItem, {
              [styles[`countdownItem--active`]]: currentNumber <= 2,
            })}
          >
            2
          </span>
          <span
            className={classNames(styles.countdownItem, {
              [styles[`countdownItem--active`]]: currentNumber <= 1,
            })}
          >
            3
          </span>
        </div>
      </div>
    </div>
  );
};
