import { useMemo, useState } from 'react';
import { Home } from '@/src/pages/Home';
import { Difficulty } from '@/src/pages/Difficulty';
import { Countdown } from '@/src/pages/Countdown';
import { Game } from '@/src/pages/Game';
import { Final } from '@/src/pages/Final';
import { PageContext } from './PageContext';

export const App = () => {
  const [page, setPage] = useState('home'); // home / difficulty / countdown / game / final

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home />;
      case 'difficulty':
        return <Difficulty />;
      case 'countdown':
        return <Countdown />;
      case 'game':
        return <Game />;
      case 'final':
        return <Final />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  return (
    <PageContext.Provider value={{ page, setPage }}>
      {renderCurrentPage}
    </PageContext.Provider>
  );
};
