import { useState, useEffect } from 'react';
import styles from './Game.module.scss';
import classNames from 'classnames';

import { DIFFICULTY } from '@/src/constants';

import backSrc from '../../images/content/card.png';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import { useAppSelector } from '@/src/hooks';
import { gameDifficultySelector } from '@/src/store/selectors/game';
import { useDispatch } from 'react-redux';
import { addMistake, addMove } from '@/src/store/slices/gameSlice';
import { generateCards } from './utils';
import sendEventToCounters from '@/src/counterEvents';

export type TCard = {
  id: number;
  name: string;
  type: string;
  img: string;
  isFound?: boolean;
  isOpen?: boolean;
  isChosen?: boolean;
  isPointer?: boolean;
};

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();
  const difficulty = useAppSelector(gameDifficultySelector) || DIFFICULTY.BASE;

  const [cards, setCards] = useState<TCard[]>(generateCards(difficulty));
  const [isShowCards, setShowCards] = useState(true);
  const [firstCard, setFirstCard] = useState<TCard | null>(null);
  const [secondCard, setSecondCard] = useState<TCard | null>(null);
  const [foundCards, setFoundCards] = useState<TCard[]>([]);
  const [moves, setMoves] = useState(0);
  let timerShowId: ReturnType<typeof setTimeout> | undefined;
  let timerCloseId: ReturnType<typeof setTimeout> | undefined;
  let timerFinalId: ReturnType<typeof setTimeout> | undefined;

  useEffect(() => {
    if (isShowCards) {
      const noShowCards = () => {
        const newCards = cards.map((card) => ({
          ...card,
          isOpen: false,
          isPointer: true,
        }));
        setCards(newCards);
        setShowCards(false);
      };

      timerShowId = setTimeout(noShowCards, 6000);
    }

    return () => clearInterval(timerShowId);
  }, [isShowCards]);

  useEffect(() => {
    if (foundCards.length === 6) {
      timerFinalId = setTimeout(() => changePage('final'), 1000);

      sendEventToCounters({
        action: difficulty === DIFFICULTY.BASE ? 'baseLevel' : 'hardLevel',
        label: 'final',
      });
    }

    return () => clearInterval(timerFinalId);
  }, [foundCards]);

  const handleClickCard = (card: TCard) => {
    if (firstCard === null) {
      setFirstCard(card);
    } else if (firstCard !== null && secondCard === null) {
      setSecondCard(card);
      const newCards = cards.map((card) => ({ ...card, isPointer: false }));
      setCards(newCards);
    }
  };

  const checkIsCorrectPair = (firstCard: TCard, secondCard: TCard | null) => {
    if (difficulty === DIFFICULTY.BASE) {
      return firstCard.name === secondCard?.name;
    }

    if (difficulty === DIFFICULTY.HARD) {
      return firstCard.type === secondCard?.type;
    }
  };

  useEffect(() => {
    if (firstCard !== null) {
      const newCards = cards.map((card) =>
        card.id === firstCard.id
          ? { ...card, isOpen: true, isChosen: true }
          : card,
      );
      setCards(newCards);
    }
    if (secondCard !== null) {
      const newCards = cards.map((card) =>
        card.id === secondCard.id
          ? { ...card, isOpen: true, isChosen: true }
          : card,
      );
      setCards(newCards);
    }

    const closeCards = () => {
      const newCards = cards.map((card) =>
        card.id === firstCard?.id || card.id === secondCard?.id
          ? { ...card, isOpen: false, isPointer: true, isChosen: false }
          : { ...card, isPointer: true },
      );
      setCards(newCards);
      setFirstCard(null);
      setSecondCard(null);
    };

    const deleteCards = () => {
      const newCards = cards.map((card) =>
        card.id === firstCard?.id || card.id === secondCard?.id
          ? { ...card, isFound: true }
          : { ...card, isPointer: true },
      );
      setCards(newCards);
      if (firstCard !== null) {
        setFoundCards((prevState) => [...prevState, firstCard]);
      }
      setFirstCard(null);
      setSecondCard(null);
    };

    if (firstCard !== null && checkIsCorrectPair(firstCard, secondCard)) {
      setMoves(moves + 1);

      dispatch(addMove());

      timerCloseId = setTimeout(deleteCards, 1000);
    }

    if (
      firstCard !== null &&
      secondCard !== null &&
      !checkIsCorrectPair(firstCard, secondCard)
    ) {
      setMoves(moves + 1);

      dispatch(addMove());
      dispatch(addMistake());

      timerCloseId = setTimeout(closeCards, 1000);
    }

    return () => clearInterval(timerCloseId);
  }, [firstCard, secondCard]);

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.cards, styles.fade)}>
        {cards &&
          cards.map((card) => (
            <div
              className={classNames(
                styles.card,
                {
                  [styles.found]: card.isFound,
                },
                {
                  [styles.open]: card.isOpen,
                },
                {
                  [styles.pointer]: card.isPointer,
                },
                {
                  [styles.chosen]: card.isChosen,
                },
              )}
              key={card.id}
              onClick={() => handleClickCard(card)}
            >
              <div className={styles.cardInner}>
                <div className={styles.cardFront}>
                  <img src={card.img} alt="" />
                </div>
                <div className={styles.cardBack}>
                  <img src={backSrc} alt="" />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
