import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import styles from './Home.module.scss';
import classNames from 'classnames';
import iconTitleSrc from '../../images/content/icon-title.svg';
import vkLogoSrc from '../../images/content/vk-logo.svg';
import hhLogoSrc from '../../images/content/hh-logo.svg';
import photoSrc from '../../images/content/photo.png';

import rulesIcon1Src from '../../images/content/icon-rules-1.svg';
import rulesIcon2Src from '../../images/content/icon-rules-2.svg';
import rulesIcon3Src from '../../images/content/icon-rules-3.svg';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import sendEventToCounters from '@/src/counterEvents';

export const Home = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const handleClickGoGame = () => {
    changePage('difficulty');

    sendAnalytics('play');
  };

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'landing', label });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Header type="home" />
        <div className={styles.main}>
          <div className={styles.image}>
            <img src={photoSrc} alt="Молодой человек" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>
              <span>
                Мемо <em>по-новому</em>
              </span>
              <img src={iconTitleSrc} alt="" />
            </div>
            <div className={styles.text}>
              <p>
                Сделать селфи со&#160;звездой, выкатить новую фичу в&#160;VK
                Видео или зависнуть на&#160;квартирнике после сдачи проекта:
                выберешь что-то одно или всё сразу?
              </p>
            </div>
            <div
              className={classNames(styles.buttons, styles[`buttons--desktop`])}
            >
              <button
                className={classNames(styles.button, styles[`button--blue`])}
                onClick={handleClickGoGame}
              >
                <span className={styles.buttonText}>
                  Играй в&#160;&#171;Мемо по-новому&#187; и&#160;узнаешь, почему
                  сотрудники так любят свою работу!
                </span>
                <span className={styles.buttonFooter}>
                  <span className={styles.buttonTitle}>Играть</span>
                  <img src={vkLogoSrc} alt="Логотип ВК" />
                </span>
              </button>
              <a
                className={classNames(styles.button)}
                href="https://rating.hh.ru/poll/"
                target="_blank"
                rel="noreferrer"
                onClick={() => sendAnalytics('vote')}
              >
                <span className={styles.buttonText}>
                  Близок наш вайб? Голосуй за&#160;VK в&#160;Рейтинге
                  работодателей России!
                </span>
                <span className={styles.buttonFooter}>
                  <span className={styles.buttonTitle}>Голосовать</span>
                  <img src={hhLogoSrc} alt="Логотип Хэдхантер" />
                </span>
              </a>
            </div>
            <div className={styles.rules}>
              <p className={styles.rulesTitle}>Что нужно делать:</p>
              <div className={styles.rulesList}>
                <div
                  className={classNames(
                    styles.rulesItem,
                    styles[`rulesItem--1`],
                  )}
                >
                  <p>Выбери уровень сложности</p>
                  <div className={styles.rulesIcon}>
                    <img src={rulesIcon1Src} alt="Кнопки уровеней сложности" />
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.rulesItem,
                    styles[`rulesItem--2`],
                  )}
                >
                  <p>Кликай карточки и&#160;находи пары</p>
                  <div className={styles.rulesIcon}>
                    <img src={rulesIcon2Src} alt="Карточки с котами" />
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.rulesItem,
                    styles[`rulesItem--3`],
                  )}
                >
                  <p>Узнай, насколько ты круче других</p>
                  <div className={styles.rulesIcon}>
                    <img src={rulesIcon3Src} alt="Пять звезд" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.buttons, styles[`buttons--mobile`])}>
          <button
            className={classNames(styles.button, styles[`button--blue`])}
            onClick={handleClickGoGame}
          >
            <span className={styles.buttonText}>
              Играй в&#160;&#171;Мемо по-новому&#187; и&#160;узнаешь, почему
              сотрудники так любят свою работу!
            </span>
            <span className={styles.buttonFooter}>
              <span className={styles.buttonTitle}>Играть</span>
              <img src={vkLogoSrc} alt="Логотип ВК" />
            </span>
          </button>
          <a
            className={classNames(styles.button)}
            href="https://rating.hh.ru/poll/"
            target="_blank"
            rel="noreferrer"
            // onClick={() => sendAnalytics('support-in-rating')}
          >
            <span className={styles.buttonText}>
              Близок наш вайб? Голосуй за&#160;VK в&#160;Рейтинге работодателей
              России!
            </span>
            <span className={styles.buttonFooter}>
              <span className={styles.buttonTitle}>Голосовать</span>
              <img src={hhLogoSrc} alt="Логотип Хэдхантер" />
            </span>
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};
