import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import styles from './Final.module.scss';
import classNames from 'classnames';
import { allFinals } from '@/src/constants';
import hhLogoSrc from '../../images/content/hh-logo.svg';
import { useAppSelector } from '@/src/hooks';
import {
  gameMistakesSelector,
  gameMovesSelector,
} from '@/src/store/selectors/game';
import { shuffle } from '@/src/utils';
import sendEventToCounters from '@/src/counterEvents';

type TFinal = {
  id: number;
  name: string;
  textId: number;
  img: string;
};

type TFinalId = number;

export const Final = () => {
  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'results', label });
  };

  const scoreMoves = useAppSelector(gameMovesSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scoreMistakes = useAppSelector(gameMistakesSelector);

  const finals: TFinal[] = [];
  const renderFinalText = (finalId: TFinalId) => {
    switch (finalId) {
      case 1:
        return (
          <span>
            Тебе <em>можно</em>
            <br />
            доверить любую
            <br />
            задачу VK!
          </span>
        );
      case 2:
        return (
          <span>
            Вот это <em>игра!</em>
            <br />
            Ты точно
            <br />
            не сотрудник VK?
          </span>
        );
      case 3:
        return (
          <span>
            В VK <em>ценят</em>
            <br />
            такую смекалку!
          </span>
        );
      case 4:
        return (
          <span>
            Да это заявка
            <br />
            на победу -
            <br />
            команда VK такое
            <br />
            точно <em>оценит!</em>
          </span>
        );
      case 5:
        return (
          <span>
            Тебе покоряются
            <br />
            все задачи <em>VK!</em>
          </span>
        );
      case 6:
        return (
          <span>
            Впечатляющий
            <br />
            результат, ты
            <br />
            точно не из <em>VK?</em>
          </span>
        );
      default:
        return <span>Ошибка состояния приложения!</span>;
    }
  };

  const randomFinals = shuffle<TFinal>(allFinals).slice(0, allFinals.length);
  const shuffleFinals = shuffle<TFinal>(randomFinals.concat(randomFinals));
  finals.push(...shuffleFinals);

  return (
    <div className={styles.wrapper}>
      <Header type="final" />
      <div className={styles.main}>
        <div className={styles.title}>
          <span>
            Вау, ты&#160;собрал все поле за&#160;&#171;{scoreMoves}
            &#187; ходов!
          </span>
        </div>
        <div className={styles.text}>
          <p>
            Сегодня ты&#160;узнал&#160;VK гораздо лучше!
            <br />
            Поддержи нас в&#160;Рейтинге работодателей России!
          </p>
        </div>
        <div
          className={classNames(
            styles.mascot,
            styles[`mascot--${finals[0].id}`],
          )}
        >
          <img className={styles.mascotImg} src={finals[0].img} alt="Маскот" />
          <p className={styles.mascotBubble}>
            {renderFinalText(finals[0].textId)}
          </p>
        </div>
        <a
          className={classNames(styles.button, styles[`button--desktop`])}
          href="https://rating.hh.ru/poll/?utm_source=vk_hh&utm_medium=referral&utm_campaign=special_project_final"
          target="_blank"
          rel="noreferrer"
          onClick={() => sendAnalytics('vote')}
        >
          <span className={styles.buttonFooter}>
            <span className={styles.buttonTitle}>Голосовать</span>
            <img src={hhLogoSrc} alt="Логотип Хэдхантер" />
          </span>
        </a>
      </div>
      <a
        className={classNames(styles.button, styles[`button--mobile`])}
        href="https://rating.hh.ru/poll/?utm_source=vk_hh&utm_medium=referral&utm_campaign=special_project_final"
        target="_blank"
        rel="noreferrer"
        onClick={() => sendAnalytics('vote')}
      >
        <span className={styles.buttonFooter}>
          <span className={styles.buttonTitle}>Голосовать</span>
          <img src={hhLogoSrc} alt="Логотип Хэдхантер" />
        </span>
      </a>
      <Footer />
    </div>
  );
};
