import { DIFFICULTY } from '@/src/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  difficulty: keyof typeof DIFFICULTY | null;
  moves: number;
  mistakes: number;
}

const initialState: IState = {
  difficulty: null,
  moves: 0,
  mistakes: 0,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setDifficulty(state, action: PayloadAction<keyof typeof DIFFICULTY>) {
      state.difficulty = action.payload;
    },
    setMoves(state, action: PayloadAction<number>) {
      state.moves = action.payload;
    },
    setMistakes(state, action: PayloadAction<number>) {
      state.mistakes = action.payload;
    },
    addMove(state) {
      state.moves += 1;
    },
    addMistake(state) {
      state.mistakes += 1;
    },
  },
});

export const { setDifficulty, setMoves, setMistakes, addMove, addMistake } =
  gameSlice.actions;

export default gameSlice;
