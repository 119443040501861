export default function sendEventToCounters(params) {
  const { action, label } = params;

  // eslint-disable-next-line no-console
  // console.log('GOAL: ', `2024vk_${action}_${label}`, params);

  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(94718735, 'reachGoal', `2024vk_${action}_${label}`);
  }
}
