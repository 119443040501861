import styles from './Header.module.scss';
import classNames from 'classnames';

// import sendEventToCounters from '../../countersEvents';

import logoSrc from '../../images/content/logo.svg';
import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  type: string;
};

export const Header = ({ type }: TProps) => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const handleClickGoGame = () => {
    changePage('difficulty');

    sendAnalytics('play');
  };

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'footer', label });
  };

  return (
    <div className={classNames(styles.header, styles[`header--${type}`])}>
      <div className={styles.logo}>
        <a href="/">
          <img src={logoSrc} alt="Логотип ВК Тим" />
        </a>
      </div>
      <div className={styles.buttons}>
        <a
          className={classNames(styles.button, styles[`button--black`])}
          href="https://rating.hh.ru/poll/?utm_source=vk_hh&utm_medium=referral&utm_campaign=special_project_start"
          target="_blank"
          rel="noreferrer"
          onClick={() => sendAnalytics('vote')}
        >
          Голосовать
        </a>
        <button
          className={classNames(styles.button, styles[`button--start`])}
          onClick={handleClickGoGame}
          type="button"
        >
          Играть
        </button>
      </div>
    </div>
  );
};
