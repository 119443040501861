export const BASE = 'baseDifficulty';
export const HARD = 'hardDifficulty';

export const enum DIFFICULTY {
  BASE = 'BASE',
  HARD = 'HARD',
}

import card1Src from './images/cards/card1.jpg';
import card2Src from './images/cards/card2.jpg';
import card3Src from './images/cards/card3.jpg';
import card4Src from './images/cards/card4.jpg';
import card5Src from './images/cards/card5.jpg';
import card6Src from './images/cards/card6.jpg';
import card7Src from './images/cards/card7.jpg';
import card8Src from './images/cards/card8.jpg';
import card9Src from './images/cards/card9.jpg';
import card10Src from './images/cards/card10.jpg';
import card11Src from './images/cards/card11.jpg';
import card12Src from './images/cards/card12.jpg';
import card13Src from './images/cards/card13.jpg';
import card14Src from './images/cards/card14.jpg';
import card15Src from './images/cards/card15.jpg';
import card16Src from './images/cards/card16.jpg';
import card17Src from './images/cards/card17.jpg';
import card18Src from './images/cards/card18.jpg';

import final1Src from './images/final/final1.png';
import final2Src from './images/final/final2.png';
import final3Src from './images/final/final3.png';
import final4Src from './images/final/final4.png';
import final5Src from './images/final/final5.png';
import final6Src from './images/final/final6.png';
import final7Src from './images/final/final7.png';
import final8Src from './images/final/final8.png';
import final9Src from './images/final/final9.png';
import final10Src from './images/final/final10.png';
import final11Src from './images/final/final11.png';
import final12Src from './images/final/final12.png';

export const allCards = [
  {
    id: 1,
    name: 'card_1',
    type: '',
    img: card1Src,
  },
  {
    id: 2,
    name: 'card_2',
    type: '',
    img: card2Src,
  },
  {
    id: 3,
    name: 'card_3',
    type: '',
    img: card3Src,
  },
  {
    id: 4,
    name: 'card_4',
    type: '',
    img: card4Src,
  },
  {
    id: 5,
    name: 'card_5',
    type: '',
    img: card5Src,
  },
  {
    id: 6,
    name: 'card_6',
    type: '',
    img: card6Src,
  },
  {
    id: 7,
    name: 'card_7',
    type: '',
    img: card7Src,
  },
  {
    id: 8,
    name: 'card_8',
    type: '',
    img: card8Src,
  },
  {
    id: 9,
    name: 'card_9',
    type: '',
    img: card9Src,
  },
  {
    id: 10,
    name: 'card_10',
    type: '',
    img: card10Src,
  },
  {
    id: 11,
    name: 'card_11',
    type: '',
    img: card11Src,
  },
  {
    id: 12,
    name: 'card_12',
    type: '',
    img: card12Src,
  },
  {
    id: 13,
    name: 'card_13',
    type: '',
    img: card13Src,
  },
  {
    id: 14,
    name: 'card_14',
    type: '',
    img: card14Src,
  },
  {
    id: 15,
    name: 'card_15',
    type: '',
    img: card15Src,
  },
  {
    id: 16,
    name: 'card_16',
    type: '',
    img: card16Src,
  },
  {
    id: 17,
    name: 'card_17',
    type: '',
    img: card17Src,
  },
  {
    id: 18,
    name: 'card_18',
    type: '',
    img: card18Src,
  },
];

export const hardCoreCardsType_1 = [
  {
    id: 1,
    name: 'card_1',
    type: 'type1',
    img: card1Src,
  },
  {
    id: 3,
    name: 'card_3',
    type: 'type2',
    img: card3Src,
  },
  {
    id: 5,
    name: 'card_5',
    type: 'type3',
    img: card5Src,
  },
  {
    id: 7,
    name: 'card_7',
    type: 'type4',
    img: card7Src,
  },
  {
    id: 9,
    name: 'card_9',
    type: 'type5',
    img: card9Src,
  },
  {
    id: 11,
    name: 'card_11',
    type: 'type6',
    img: card11Src,
  },
  {
    id: 13,
    name: 'card_13',
    type: 'type7',
    img: card13Src,
  },
  {
    id: 15,
    name: 'card_15',
    type: 'type8',
    img: card15Src,
  },
  {
    id: 17,
    name: 'card_17',
    type: 'type9',
    img: card17Src,
  },
];

export const hardCoreCardsType_2 = [
  {
    id: 2,
    name: 'card_2',
    type: 'type1',
    img: card2Src,
  },
  {
    id: 4,
    name: 'card_4',
    type: 'type2',
    img: card4Src,
  },
  {
    id: 6,
    name: 'card_6',
    type: 'type3',
    img: card6Src,
  },
  {
    id: 8,
    name: 'card_8',
    type: 'type4',
    img: card8Src,
  },
  {
    id: 10,
    name: 'card_10',
    type: 'type5',
    img: card10Src,
  },
  {
    id: 12,
    name: 'card_12',
    type: 'type6',
    img: card12Src,
  },
  {
    id: 14,
    name: 'card_14',
    type: 'type7',
    img: card14Src,
  },
  {
    id: 16,
    name: 'card_16',
    type: 'type8',
    img: card16Src,
  },
  {
    id: 18,
    name: 'card_18',
    type: 'type9',
    img: card18Src,
  },
];

export const allFinals = [
  {
    id: 1,
    name: 'final_1',
    textId: 1,
    img: final1Src,
  },
  {
    id: 2,
    name: 'final_2',
    textId: 2,
    img: final2Src,
  },
  {
    id: 3,
    name: 'final_3',
    textId: 3,
    img: final3Src,
  },
  {
    id: 4,
    name: 'final_4',
    textId: 4,
    img: final4Src,
  },
  {
    id: 5,
    name: 'final_5',
    textId: 5,
    img: final5Src,
  },
  {
    id: 6,
    name: 'final_6',
    textId: 6,
    img: final6Src,
  },
  {
    id: 7,
    name: 'final_7',
    textId: 1,
    img: final7Src,
  },
  {
    id: 8,
    name: 'final_8',
    textId: 2,
    img: final8Src,
  },
  {
    id: 9,
    name: 'final_9',
    textId: 3,
    img: final9Src,
  },
  {
    id: 10,
    name: 'final_10',
    textId: 4,
    img: final10Src,
  },
  {
    id: 11,
    name: 'final_11',
    textId: 5,
    img: final11Src,
  },
  {
    id: 12,
    name: 'final_12',
    textId: 6,
    img: final12Src,
  },
];
